export const hcsMessage = [
  {
    header: "Submit a message to a topic",
    subHeader: "",
    description: "Publish a message into hedera network.",
    services: [
      {
        url: "/api/hcs/v2/topic/submit/message/{topicId}",
        type: "post",
        desc: "This api is used to publish a message into hedera network from IHI HCS service",

        requestFieldDetails: {
          headerParam: [
            {
              fieldName: "topicId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "To publish the message, the topic id is must . it will be provided by the ihi after creating topic from ihi",
            },
          ],
          bodyParam: [
            {
              fieldName: "apiName",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "This is used for identifying from which api we published the content from the project",
            },
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "clientId is nothing but project user name which we provide to the other projects",
            },
            {
              fieldName: "createdDate",
              dataType: "Date",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "created time when we are publishing",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "message",
            value: "messages is submitted successfully!",
            dataType: "String",
            description:
              "The response will be null, if the status code is 200 and says the message is published successfully and return error message if the status code is other than 200.",
          },
        ],
      },
    ],
  },
];

import { Grid } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import BodyComp from "./common/api-comp/BodyComp";
import ResponseComp from "./common/api-comp/ResponseComp";
import HedareComp from "./common/api-comp/HedareComp";
import { toast } from "react-toastify";

function Interactive({ apidata }) {
  const [value, setValue] = useState(apidata.initialReq);
  const [respone, setResponse] = useState();
  const [status, setStatus] = useState("");
  const [headerData, setHeaderData] = useState(apidata.headrerBody);
  const [isLoading, setLoading] = useState(false);
  const authorized = sessionStorage.getItem("authorized");

  const onchangeElement = (i, value) => {
    const arr = [...headerData];
    const obj = { ...arr[i] };

    obj["value"] = value;
    arr[i] = obj;
    setHeaderData(arr);
  };

  const getURL = () => {
    let headurl = "";
    if (headerData.length > 0) {
      headerData.filter((ele, i) => {
        headurl = headurl + "/" + ele.value;
      });
    }
    return headurl;
  };

  const handleClick = async () => {
    if (apidata.screenName !== "generateToken" && !authorized) {
      toast.warning("Unauthorized!");
    } else {
      const headUrl = getURL();
      setResponse(null);
      setLoading(true);
      await axios({
        method: apidata.methodType,
        url: apidata.url + headUrl,
        data: value,
        headers: {
          Authorization: authorized ? authorized : "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          setResponse(response.data);
          setStatus(200);
          if (response.data.accessToken) {
            sessionStorage.setItem(
              "accessToken",
              "Bearer " + response.data.accessToken
            );
            sessionStorage.setItem("tokenType", response.data.tokenType);
            sessionStorage.setItem("id", response.data.id);
            sessionStorage.setItem("username", response.data.username);
            sessionStorage.setItem("email", response.data.email);
            sessionStorage.setItem("roles", response.data.roles[0]);
          }
          setLoading(false);
        })
        .catch((error) => {
          setResponse(error.response.data);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <br />
      <Grid container rowSpacing={1}>
        {headerData.length > 0 ? (
          <Grid item xs={12}>
            <HedareComp
              headrerBody={headerData}
              onchangeElement={onchangeElement}
            />
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <BodyComp
            handleClick={handleClick}
            setValue={setValue}
            value={value}
            disableTry={false}
            screenName={apidata.screenName}
          />
        </Grid>
        <Grid item xs={12}>
          <ResponseComp
            respone={respone}
            status={status}
            screenName={apidata.screenName}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Interactive;

export const GetHcsMessageDesc = [
  {
    header: "Get HCS message",
    subHeader: "",
    description: "Getting message from hedera blockchain.",
    services: [
      {
        url: "/api/hcs/v2/fetch/ledger/message/{clientId}/{page}/{size}",
        type: "get",
        desc: "This api is used to get a message from hedera blockchain from ihi hcs service",

        requestFieldDetails: {
          headerParam: [
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "To get the message client id is must . It is nothing but project user name which we provide to the other projects",
            },
            {
              fieldName: "page",
              dataType: "Integer",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "It is used for pagination",
            },
            {
              fieldName: "size",
              dataType: "Integer",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "It is used for getting number of pages per page",
            },
          ],
          bodyParam: [],
        },
        responseFieldDetails: [],
      },
    ],
  },
];

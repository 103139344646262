export const REACT_APP_AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL
  ? process.env.REACT_APP_AUTH_BASE_URL
  : "";

export const REACT_APP_HCS_BASE_URL = process.env.REACT_APP_HCS_BASE_URL
  ? process.env.REACT_APP_HCS_BASE_URL
  : "";

export const REACT_APP_HEDERA_BASE_URL = process.env.REACT_APP_HEDERA_BASE_URL
  ? process.env.REACT_APP_HEDERA_BASE_URL
  : "";

export const REACT_APP_HTS_BASE_URL = process.env.REACT_APP_HTS_BASE_URL
  ? process.env.REACT_APP_HTS_BASE_URL
  : "";

export const ADMIN_AUTH_BASE_URL = process.env.REACT_APP_ADMIN_AUTH_URL
  ? process.env.REACT_APP_ADMIN_AUTH_URL
  : "";

export const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL
  ? process.env.REACT_APP_ADMIN_BASE_URL
  : "";
export const acceptButton = "accept-btn";
export const rejectButton = "reject-btn";

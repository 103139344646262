export const currentTotalSupplyDesc = [
  {
    header: "Current total Supply",
    subHeader: "",
    description: "Api reference to fetch current total supply.",
    services: [
      {
        url: "/api/hts/token/current/totalsupply/{token}/{clientId}",
        type: "post",
        desc: "This api is used to fetch current total supply.",

        requestFieldDetails: {
          headerParam: [
            {
              fieldName: "token",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "Token id which is generated during token creation.",
            },
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "Client id.",
            },
          ],
          bodyParam: [],
        },
        responseFieldDetails: [
          {
            fieldName: "response",
            value: "10000",
            dataType: "String",
            description: "Response values is total wallet balance.",
          },
        ],
      },
    ],
  },
];

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userName: "",
    signInLoading: false,
  },
  reducers: {
    userNameAction: (state, { payload }) => {
      state.userName = payload.response;
    },
    signInAction: (state, { payload }) => {
      state.signInLoading = payload.loading;
    },
  },
});
export const { signInAction, userNameAction } = authSlice.actions;
export const authSelector = (state) => state.auth;
const authReducer = authSlice.reducer;
export default authReducer;

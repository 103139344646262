export const createTokenDesc = [
  {
    header: "Create Token",
    subHeader: "API reference for Create Token",
    description: "API to Create a new Token.",
    services: [
      {
        url: "api/hts/token/create",
        type: "post",
        desc: "To create new token.",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "adminKey",
              dataType: "Boolean",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "",
            },
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The field represents the project user name which is used while registering",
            },
            {
              fieldName: "decimal",
              dataType: "float",
              required: true,
              minLength: 3,
              maxLenth: 10,
              description:
                "The decimal places which token balance should be shown and it is user defined eg:100.00",
            },
            {
              fieldName: "defaultFrozen",
              dataType: "Boolean",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "",
            },
            {
              fieldName: "freezeKey",
              dataType: "Boolean",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "",
            },
            {
              fieldName: "initialSupply",
              dataType: "float",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The initial amount of the tokenId.",
            },
            {
              fieldName: "kycKey",
              dataType: "Boolean",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "",
            },
            {
              fieldName: "supplyManagerKey",
              dataType: "Boolean",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "",
            },
            {
              fieldName: "symbol",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "Symbol of token",
            },
            {
              fieldName: "tokenId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "NFT token Id",
            },
            {
              fieldName: "tokenName",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "Name of the token",
            },
            {
              fieldName: "tokenRenewalAccount",
              dataType: "Boolean",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "",
            },
            {
              fieldName: "treasuryAccountId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The ID of the wallet",
            },
            {
              fieldName: "treasuryPrivateKey",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The private key which is generated during wallet creation ",
            },
            {
              fieldName: "wipeKey",
              dataType: "Boolean",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "tokenId",
            value: "0.0.45909774",
            type: "String",
            description: "ID of the Token",
          },
          {
            fieldName: "tokenName",
            value: "BT",
            type: "String",
            description: "The name which is user defined and unique",
          },
          {
            fieldName: "symbol",
            value: "$",
            type: "String",
            description: "The symbol which is user defined and unique",
          },
          {
            fieldName: "treasuryAccountId",
            value: "0.0.45904562",
            type: "String",
            description: "The ID of the wallet",
          },
          {
            fieldName: "treasuryPrivateKey",
            value: "",
            type: "String",
            description: "",
          },
          {
            fieldName: "decimal",
            value: 2,
            type: "String",
            description:
              "The decimal places which token balance should be shown and it is user defined eg:100.00",
          },
          {
            fieldName: "initialSupply",
            value: "10000",
            type: "String",
            description: "The initial amount of the tokenId",
          },
          {
            fieldName: "adminKey",
            value:
              "302a300506032b657003210060289c0451b799f7bf53a131dbe673a8396a83a9a0f05a6578920020a7edb743",
            type: "String",
            description: "An unique key",
          },
          {
            fieldName: "defaultFrozen",
            value: "",
            type: "String",
            description: "",
          },
          {
            fieldName: "freezeKey",
            value:
              "302a300506032b65700321003eb4be16df36a50d74154bd24846aa2c4ab9867a84ff117b96f00d062c9d19f6",
            type: "String",
            description: "An unique key",
          },
          {
            fieldName: "kycKey",
            value:
              "302a300506032b65700321003eb4be16df36a50d74154bd24846aa2c4ab9867a84ff117b96f00d062c9d19f6",
            type: "String",
            description: "An unique key",
          },
          {
            fieldName: "supplyManagerKey",
            value:
              "302a300506032b65700321003eb4be16df36a50d74154bd24846aa2c4ab9867a84ff117b96f00d062c9d19f6",
            type: "String",
            description: "An unique key",
          },
          {
            fieldName: "tokenRenewalAccount",
            value: "0.0.45904562",
            type: "String",
            description: "The ID of the wallet",
          },
          {
            fieldName: "wipeKey",
            value:
              "302a300506032b65700321003eb4be16df36a50d74154bd24846aa2c4ab9867a84ff117b96f00d062c9d19f6",
            type: "String",
            description: "An unique key",
          },
          {
            fieldName: "tokenInitValue",
            value: "10000",
            type: "String",
            description: "The initial amount",
          },
        ],
      },
    ],
  },
];

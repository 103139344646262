import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MiniDrawer from "../../components/navBar/Sidenav";
import { Box, Card, Grid, TablePagination } from "@mui/material";
import { getAllClientsApi } from "../../redux/action/adminAction";
import { adminSelector } from "../../redux/slice/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/common/loading/Loading";

function Dashboard() {
  const dispatch = useDispatch();
  const { getAllClients, allclientElements, getClientsLoading } =
    useSelector(adminSelector);
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const [api, setApi] = useState(0);
  const paginationRowsOptions = [5, 10, 20, 50, 100];

  useEffect(() => {
    dispatch(getAllClientsApi(page, size));
  }, [page, size]);
  // useEffect(() => {
  //   // console.log("EXEC");
  //   dispatch(getAllClientsApi(0, size));
  // }, []);
  // useEffect(() => {
  //   console.log("EXEC");
  //   dispatch(getAllClientsApi(page + 1, size));
  // }, [page, size]);
  // useEffect(() => {
  //   if (api === 0) {
  //     dispatch(getAllClientsApi(api, size));
  //     setApi(1);
  //   } else {
  //     dispatch(getAllClientsApi(page, size));
  //   }
  // }, [page, size]);

  const handlePerRowsChange = async (event) => {
    setPage(0);
    setSize(+event.target.value);
  };

  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
  };

  // console.log(getClientsLoading);

  return (
    <main className="respon">
      <div>
        <MiniDrawer />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
        </Box>
        {getAllClients != false && (
          <div
            className="dashtable"
            style={{ margin: "auto", padding: "2rem" }}
          >
            <Grid xs={12} md={6} lg={6} xl={3}>
              <h5
                style={{
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                Clients
              </h5>

              <Card style={{}} className="cardtable" sx={{ overflowX: "auto" }}>
                <table
                  style={{ textAlign: "center" }}
                  className
                  table
                  table-border
                  datatable
                  dataTable-table
                >
                  <thead>
                    <tr class="thead">
                      <th scope="col">S.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">PhoneNumber</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAllClients?.map((e, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{e.firstName}</td>
                          <td>{e.email}</td>
                          <td>{e.mobileNum}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <TablePagination
                  rowsPerPageOptions={paginationRowsOptions}
                  component="div"
                  count={allclientElements || 0}
                  rowsPerPage={size}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handlePerRowsChange}
                  className="pagination-text"
                />
              </Card>
            </Grid>
          </div>
        )}
      </div>
      {getClientsLoading && <Loading />}
    </main>
  );
}

export default Dashboard;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

import { Grid, TextField } from "@mui/material";

function HedareComp({ headrerBody, onchangeElement, selectedTab }) {
  return (
    <div>
      <Grid container rowSpacing={1}>
        {headrerBody.map((ele, i) => (
          <Grid item xs={12} key={i}>
            <TextField
              required
              fullWidth
              id="filled-required"
              label={ele.name}
              variant="outlined"
              value={ele.value}
              disabled={selectedTab === 0 ? true : false}
              onChange={(e) => onchangeElement(i, e.target.value)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default HedareComp;

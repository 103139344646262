import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, Card, Grid, TablePagination } from "@mui/material";
import MiniDrawer from "../../components/navBar/Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector } from "../../redux/slice/adminSlice";
import { useState } from "react";
import { useEffect } from "react";
import { headraFeesApi } from "../../redux/action/adminAction";
import LoadingPage from "../../components/common/loading/Loading";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import FeesModal from "../../common/FeesModal";
import { useDebouncedEffect } from "../../common/debounce";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function Fees() {
  const dispatch = useDispatch();
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const paginationRowsOptions = [5, 10, 20, 50, 100];
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [modalName, setModalName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [oldArr, setOldArr] = useState([]);
  const { getHederaFeesList, feesElements, feesLoading } =
    useSelector(adminSelector);
  const [feesArr, setFeesArr] = useState([]);

  useEffect(() => {
    searchHandler();
  }, [page, size]);

  // useEffect(() => {
  //   if (getHederaFeesList) setFeesArr(getHederaFeesList);
  // }, [getHederaFeesList]);

  const handlePerRowsChange = async (event) => {
    setPage(0);
    setSize(+event.target.value);
  };
  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
  };

  useDebouncedEffect(
    () => {
      searchHandler();
    },
    1000,
    [searchText]
  );

  const searchHandler = () => {
    dispatch(headraFeesApi(page, size, searchText || null, setFeesArr));
  };

  const feesEdit = (e) => {
    setModalName("update");
    setOpen(true);
    setData(e);
  };

  const addFees = () => {
    setModalName("add");
    setOpen(true);
    setData({});
  };

  const sortHandler = (sort, str) => {
    let arr = [...feesArr];
    if (str === "operations") {
      arr.sort((a, b) => {
        let fa = a.operations.toLowerCase();
        let fb = b.operations.toLowerCase();
        if (sort === "asce" ? fa > fb : fa < fb) {
          return 1;
        }
        if (sort === "asce" ? fa < fb : fa > fb) {
          return -1;
        }
        return 0;
      });
    } else if (str === "priceInUsd") {
      arr.sort((a, b) =>
        sort === "asce"
          ? Number(a.priceInUsd) - Number(b.priceInUsd)
          : Number(b.priceInUsd) - Number(a.priceInUsd)
      );
    } else if (str === "ihiFeeInUsd") {
      arr.sort((a, b) =>
        sort === "asce"
          ? Number(a.ihiFeeInUsd) - Number(b.ihiFeeInUsd)
          : Number(b.ihiFeeInUsd) - Number(a.ihiFeeInUsd)
      );
    } else {
      arr.sort((a, b) =>
        sort === "asce"
          ? Number(a.profitFeeInUsd) - Number(b.profitFeeInUsd)
          : Number(b.profitFeeInUsd) - Number(a.profitFeeInUsd)
      );
    }
    setFeesArr(arr);
  };

  return (
    <div>
      <main className="respon">
        <div>
          <MiniDrawer />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
          </Box>
          <Grid xs={12} md={12} lg={12} xl={9} sm={12} p={2}>
            <div className="wallettkn" style={{ marginBottom: "10px" }}>
              <div>
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  name="search"
                  placeholder="Search Name"
                  style={{
                    border: "solid 1px #0C314E",
                    borderRadius: "5px",
                    padding: "12px",
                    outline: "none",
                  }}
                />
              </div>
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #0C314E",
                  borderRadius: "5px",
                  mr: 2,
                }}
                onClick={addFees}
                className="searchbut"
              >
                Add Fees
                <PlaylistAddIcon
                  sx={{
                    ml: 1,
                    color: "palegreen",
                    fontSize: "20px",
                  }}
                />
              </Button>
            </div>
            <h5
              style={{
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              Hedera Fees
            </h5>
            <Card className="cardtable" sx={{ overflowX: "auto" }}>
              <table
                style={{ textAlign: "center" }}
                className
                table
                table-border
                datatable
                dataTable-table
              >
                <thead>
                  <tr class="thead">
                    <th scope="col">Service</th>
                    <th scope="col">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Operation</p>
                        <div className="sorting_div">
                          <ArrowDropUpIcon
                            className="down_arrow"
                            onClick={() => sortHandler("desc", "operations")}
                          />
                          <ArrowDropDownIcon
                            className="down_arrow"
                            onClick={() => sortHandler("asce", "operations")}
                          />
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Hedera Fee (USD)</p>
                        <div className="sorting_div">
                          <ArrowDropUpIcon
                            className="down_arrow"
                            onClick={() => sortHandler("desc", "priceInUsd")}
                          />
                          <ArrowDropDownIcon
                            className="down_arrow"
                            onClick={() => sortHandler("asce", "priceInUsd")}
                          />
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>IHI Fee (USD)</p>
                        <div className="sorting_div">
                          <ArrowDropUpIcon
                            className="down_arrow"
                            onClick={() => sortHandler("desc", "ihiFeeInUsd")}
                          />
                          <ArrowDropDownIcon
                            className="down_arrow"
                            onClick={() => sortHandler("asce", "ihiFeeInUsd")}
                          />
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Profit (USD)</p>
                        <div className="sorting_div">
                          <ArrowDropUpIcon
                            className="down_arrow"
                            onClick={() =>
                              sortHandler("desc", "profitFeeInUsd")
                            }
                          />
                          <ArrowDropDownIcon
                            className="down_arrow"
                            onClick={() =>
                              sortHandler("asce", "profitFeeInUsd")
                            }
                          />
                        </div>
                      </div>
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {feesArr?.length > 0 ? (
                      feesArr?.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td>{e.service}</td>
                            <td>{e.operations}</td>
                            <td>{e.priceInUsd}</td>
                            <td>{e.ihiFeeInUsd}</td>
                            <td>{e.profitFeeInUsd}</td>
                            <td>
                              <EditIcon
                                sx={{ color: "palegreen", cursor: "pointer" }}
                                onClick={() => feesEdit(e)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6}>No records are available yet!</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>

              <TablePagination
                rowsPerPageOptions={paginationRowsOptions}
                component="div"
                count={feesElements || 0}
                rowsPerPage={size}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePerRowsChange}
                className="pagination-text"
              />
            </Card>
          </Grid>
        </div>

        {feesLoading && <LoadingPage />}
      </main>

      <FeesModal
        open={open}
        setOpen={setOpen}
        data={data}
        title={modalName}
        size={size}
        setFeesArr={setFeesArr}
      />
    </div>
  );
}

export default Fees;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

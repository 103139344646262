import {
  REACT_APP_AUTH_BASE_URL,
  REACT_APP_HCS_BASE_URL,
  REACT_APP_HEDERA_BASE_URL,
  REACT_APP_HTS_BASE_URL,
} from "../../common/constants";
export const generateToken = {
  url: REACT_APP_AUTH_BASE_URL + "token/generate",
  HeaderUrl: REACT_APP_AUTH_BASE_URL + "token/generate",
  methodType: "post",
  reqBody: { username: "admin", password: "password@123" },
  headrerBody: [],
  responseBody: {
    accessToken:
      "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJpaGlfdGVzdCIsImlhdCI6MTY1NTEyNDMzMCwiZXhwIjoxNjU1MjEwNzMwfQ.ksFPlE3ngvQ81yUHVeJRMEaBbX2d_KXfa2_cMDQ_iJEKx4Hrrkhk1A5mglUhTkBH1bfkKEASpsYhevlkdJyhAA",
    email: "ihitest@yopmail.com",
    id: "82cb9a7a-02ee-458f-af17-a10ffd4f75c4",
    refreshToken: "3eebc79d-712e-4824-91d0-62f33080c0ce",
    roles: ["ROLE_USER"],
    tokenType: "Bearer",
    username: "ihi_test",
  },
  initialReq: { username: "", password: "" },
  screenName: "generateToken",
};

export const postHcsMessage = {
  // url: `https://hcs.ihi.teams-zabel-nft.com/api/hcs/v2/topic/submit/message/${"0.0.29693910"}`,
  url: `${REACT_APP_HCS_BASE_URL}v2/topic/submit/message`,
  HeaderUrl: `${REACT_APP_HCS_BASE_URL}v2/topic/submit/message`,
  methodType: "post",
  headrerBody: [
    {
      name: "topicId",
      type: "String",
      value: "0.0.29693910",
    },
  ],
  reqBody: {
    apiName: "api/hedera/wallet/create",
    clientId: "justpayme@gmail.com",
    createdDate: "",
    memo: "JUSTPAYME-WEB-CUSTOMER",
    message: "test message",
    role: "Role_user",
    serviceType: "publish",
    status: "APPROVED",
    topicId: "0.0.29693910",
    userId: "82cb9a7a-02ee-458f-af17-a10ffd4f75c4",
  },
  responseBody: {
    status: 200,
    message: "messages is submitted successfully!",
    response: null,
    httpStatus: "OK",
  },
  initialReq: {
    apiName: "",
    clientid: "",
    createdDate: "",
    memo: "",
    message: "",
    role: "",
    serviceType: "",
    status: "",
    topicId: "",
    userId: "",
  },
  screenName: "submitMessage",
};

export const getHcsMessage = {
  // url: "https://hcs.ihi.teams-zabel-nft.com/api/hcs/v2/fetch/ledger/message/justpayme@gmail.com/1/5",
  url: `${REACT_APP_HCS_BASE_URL}v2/fetch/ledger/message`,
  HeaderUrl: `${REACT_APP_HCS_BASE_URL}v2/fetch/ledger/message/{clientId}/{page}/{size}`,
  methodType: "get",
  reqBody: {},
  headrerBody: [
    {
      name: "clientId",
      type: "String",
      value: "justpayme@gmail.com",
    },
    {
      name: "page",
      type: "String",
      value: "1",
    },
    {
      name: "size",
      type: "String",
      value: "5",
    },
  ],
  responseBody: {
    status: 200,
    message: "Ledger messages fetched successfully",
    response: {
      content: [
        {
          id: "7305",
          data: '{"firstName":"Smith","lastName":"Roy","walletId":"0.0.34963137","Email":"Roysmith@yopmail.com","mobileNumber":"+1(701) 072-0492","userId":"7836ca51-fc0f-4506-b81e-28eda185984a"}',
          createdTime: "2022-06-02T09:51:57.970Z",
          memo: "JUSTPAYME-MOBILE-CUSTOMER",
          clientId: "justpayme@gmail.com",
          topicId: "0.0.29693910",
          consensusTimeStampSeconds: 1654163515,
          consensusTimeStampNanos: 24976000,
          status: "APPROVED",
        },
        {
          id: "7306",
          data: "HcsBankTransaction(commercialBankId=4a095c46-5706-4b79-81c7-419ddeff9a36, centralBankId=0b9f0359-66b3-4bb8-a075-2c1cec2c3472, amountBefore=785124, amountRequested=10, paidStatus=PAID_IN, notes=, transactionId=2c409b2e-c858-44a2-9a2a-550efdb9ab60, methodName=null, fromWalletId=0.0.34049833, towalletId=0.0.34856390)",
          createdTime: "2022-06-02T09:52:18.334Z",
          memo: "JUSTPAYME-WEB-COMMERCIALBANK",
          clientId: "justpayme@gmail.com",
          topicId: "0.0.29693910",
          consensusTimeStampSeconds: 1654163534,
          consensusTimeStampNanos: 890106000,
          status: "SUCCESS",
        },
        {
          id: "7307",
          data: "HcsBankTransaction(commercialBankId=4a095c46-5706-4b79-81c7-419ddeff9a36, centralBankId=0b9f0359-66b3-4bb8-a075-2c1cec2c3472, amountBefore=152114, amountRequested=500, paidStatus=PAID_IN, notes=, transactionId=f8912d77-43b2-46c4-bf60-118c8a67d986, methodName=null, fromWalletId=0.0.34049833, towalletId=0.0.34856390)",
          createdTime: "2022-06-02T09:53:11.099Z",
          memo: "JUSTPAYME-WEB-COMMERCIALBANK",
          clientId: "justpayme@gmail.com",
          topicId: "0.0.29693910",
          consensusTimeStampSeconds: 1654163586,
          consensusTimeStampNanos: 929901011,
          status: "SUCCESS",
        },
        {
          id: "7308",
          data: "HcsBankTransaction(commercialBankId=4a095c46-5706-4b79-81c7-419ddeff9a36, centralBankId=0b9f0359-66b3-4bb8-a075-2c1cec2c3472, amountBefore=152114, amountRequested=500, paidStatus=PAID_IN, notes=, transactionId=1b672586-8119-42ab-9da3-d1881afa724f, methodName=null, fromWalletId=0.0.34049833, towalletId=0.0.34856390)",
          createdTime: "2022-06-02T09:53:11.105Z",
          memo: "JUSTPAYME-WEB-COMMERCIALBANK",
          clientId: "justpayme@gmail.com",
          topicId: "0.0.29693910",
          consensusTimeStampSeconds: 1654163587,
          consensusTimeStampNanos: 342216000,
          status: "SUCCESS",
        },
        {
          id: "7309",
          data: "HcsBankTransaction(commercialBankId=a8c1e468-62d9-49b2-8bb9-2ae98ee11bf1, centralBankId=0b9f0359-66b3-4bb8-a075-2c1cec2c3472, amountBefore=992006, amountRequested=1000000, paidStatus=PAID_IN, notes=, transactionId=25b4445b-3e70-4cf4-ba0f-ec633fd09639, methodName=null, fromWalletId=0.0.34049833, towalletId=0.0.34861476)",
          createdTime: "2022-06-02T09:53:32.260Z",
          memo: "JUSTPAYME-WEB-COMMERCIALBANK",
          clientId: "justpayme@gmail.com",
          topicId: "0.0.29693910",
          consensusTimeStampSeconds: 1654163609,
          consensusTimeStampNanos: 143325544,
          status: "SUCCESS",
        },
      ],
      pageable: {
        sort: {
          empty: true,
          sorted: false,
          unsorted: true,
        },
        offset: 5,
        pageNumber: 1,
        pageSize: 5,
        paged: true,
        unpaged: false,
      },
      last: false,
      totalPages: 190,
      totalElements: 948,
      size: 5,
      number: 1,
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      first: false,
      numberOfElements: 5,
      empty: false,
    },
    httpStatus: "OK",
  },
  initialReq: {},
  screenName: "getHcsMessage",
};

export const walletApiData = {
  url: `${REACT_APP_HEDERA_BASE_URL}wallet/create`,
  HeaderUrl: `${REACT_APP_HEDERA_BASE_URL}wallet/create`,
  methodType: "post",
  reqBody: {},
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "Wallet created successfully",
    response: {
      account: "0.0.45961455",
      privatekey:
        "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
      shard: 0,
      real: 0,
      accoundId: 45961455,
      publickey:
        "302a300506032b65700321008750caae83c83b398196d7f35f343ea55d617f6bd60ab0d2b34359603b1626b4",
    },
    httpStatus: "OK",
  },
  initialReq: {},
  screenName: "createWallet",
};

export const createFileApiData = {
  url: `https://gateway-ihi.getenergy.io/api/nft/v2/file/create`,
  HeaderUrl: `https://gateway-ihi.getenergy.io/api/nft/v2/file/create`,
  methodType: "post",
  reqBody: {
    clientId: "justpayme@gmail.com",
    fileContent: "testing",
    userId: "82cb9a7a-02ee-458f-af17-a10ffd4f75c4",
  },
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "File created successfully",
    response: "0.0.45966788",
    httpStatus: "OK",
  },
  initialReq: { clientId: "", fileContent: "", userId: "" },
  screenName: "FileCreation",
};

export const NftTokenApiData = {
  url: `${REACT_APP_HTS_BASE_URL}nft/create`,
  HeaderUrl: `${REACT_APP_HTS_BASE_URL}nft/create`,
  methodType: "post",
  reqBody: {
    clientId: "justpayme@gmail.com",
    fileId: "0.0.45966788",
    operatorId: "0.0.45966793",
    operatorKey:
      "302e020100300506032b65700422042081519c4fe0984fdf5d35271b8ec6a641e3693388d6ce2d0e6bd18f90b4943004",
  },
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "NFT token created successfully",
    response: "0.0.45966795",
    httpStatus: "OK",
  },

  initialReq: {
    clientId: "",
    fileId: "",
    operatorId: "",
    operatorKey: "",
  },
  screenName: "createWallet",
};

export const createTokenApiData = {
  url: `${REACT_APP_HTS_BASE_URL}token/create`,
  HeaderUrl: `${REACT_APP_HTS_BASE_URL}token/create`,
  methodType: "post",
  reqBody: {
    adminKey: true,
    clientId: "justpayme@gmail.com",
    decimal: 2,
    defaultFrozen: true,
    freezeKey: true,
    initialSupply: 10000,
    kycKey: true,
    supplyManagerKey: true,
    symbol: "$",
    // tokenId: "0.0.45966793",
    tokenName: "Dollar",
    tokenRenewalAccount: true,
    treasuryAccountId: "0.0.45961455",
    treasuryPrivateKey:
      "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
    wipeKey: true,
  },
  headrerBody: [],
  responseBody: {
    adminKey: true,
    clientId: "justpayme@gmail.com",
    decimal: 2,
    defaultFrozen: true,
    freezeKey: true,
    initialSupply: 10000,
    kycKey: true,
    supplyManagerKey: true,
    symbol: "$",
    // tokenId: "0.0.45966793",
    tokenName: "Dollar",
    tokenRenewalAccount: true,
    treasuryAccountId: "0.0.45961455",
    treasuryPrivateKey:
      "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
    wipeKey: true,
  },

  initialReq: {
    adminKey: true,
    clientId: "",
    decimal: 0,
    defaultFrozen: true,
    freezeKey: true,
    initialSupply: 0,
    kycKey: true,
    supplyManagerKey: true,
    symbol: "",
    // tokenId: "",
    tokenName: "",
    tokenRenewalAccount: true,
    treasuryAccountId: "",
    treasuryPrivateKey: "",
    wipeKey: true,
  },
  screenName: "CreateToken",
};

export const TokenBalanceApi = {
  url: `https://gateway-ihi.getenergy.io/api/token/balance`,
  HeaderUrl: `https://gateway-ihi.getenergy.io/api/token/balance`,
  methodType: "post",
  reqBody: {
    clientId: "justpayme@gmail.com",
    operatorId: "0.0.45961455",
    tokenId: "0.0.45969365",
  },
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "Fetched Account Balance Successfully",
    response: 10000,
    httpStatus: "OK",
  },

  initialReq: {
    clientId: "",
    operatorId: "",
    tokenId: "",
  },
  screenName: "",
};

export const BurnTokenApi = {
  url: `${REACT_APP_HTS_BASE_URL}token/burn`,
  HeaderUrl: `${REACT_APP_HTS_BASE_URL}token/burn`,
  methodType: "post",
  reqBody: {
    amount: 4,
    clientId: "justpayme@gmail.com",
    tokenId: "0.0.45969365",
    tokenOwnerPrivateKey:
      "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
  },
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "Fetched current total supply successfully",
    response: 9996,
    httpStatus: "OK",
  },

  initialReq: {
    amount: 0,
    clientId: "",
    tokenId: "",
    tokenOwnerPrivateKey: "",
  },
  screenName: "BurnToken",
};

export const MintTokenApi = {
  url: `${REACT_APP_HTS_BASE_URL}token/mint`,
  HeaderUrl: `${REACT_APP_HTS_BASE_URL}token/mint`,
  methodType: "post",
  reqBody: {
    amount: 4,
    clientId: "justpayme@gmail.com",
    tokenId: "0.0.45969365",
    tokenOwnerPrivateKey:
      "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
  },
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "Fetched current total supply successfully",
    response: 10000,
    httpStatus: "OK",
  },

  initialReq: {
    amount: 0,
    clientId: "",
    tokenId: "",
    tokenOwnerPrivateKey: "",
  },
  screenName: "MintToken",
};

export const currentTotalSupplyApi = {
  url: `${REACT_APP_HTS_BASE_URL}token/current/totalsupply`,
  HeaderUrl: `${REACT_APP_HTS_BASE_URL}token/current/totalsupply/{token}/{clientId}`,
  methodType: "post",
  reqBody: {},
  headrerBody: [
    {
      name: "tokenId",
      type: "String",
      value: "0.0.45969365",
    },
    {
      name: "clientId",
      type: "String",
      value: "justpayme@gmail.com",
    },
  ],
  responseBody: {
    status: 200,
    message: "Fetched current total supply successfully",
    response: 10000,
    httpStatus: "OK",
  },

  initialReq: {},
  screenName: "CurrentTotalSupply",
};

export const AssociateKycUserApiData = {
  url: `${REACT_APP_HTS_BASE_URL}token/user/associatekyc`,
  HeaderUrl: `${REACT_APP_HTS_BASE_URL}token/user/associatekyc`,
  methodType: "post",
  reqBody: {
    adminOperatorId: "0.0.45961455",
    adminOperatorKey:
      "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
    clientId: "justpayme@gmail.com",
    operatorId: "0.0.46018633",
    operatorKey:
      "302e020100300506032b657004220420bebcf7647d8ae711fd2e009e2e3d18b32f333cb5de692b8cd560265c3bf00aba",
    tokenId: "0.0.46018711",
  },
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "Associate KYC user fetched successfully",
    response: "1000",
    httpStatus: "OK",
  },

  initialReq: {
    adminOperatorId: "",
    adminOperatorKey: "",
    clientId: "",
    operatorId: "",
    operatorKey: "",
    tokenId: "",
  },
  screenName: "AssociateKycUser",
};

export const EnableKycUserApiData = {
  url: `https://gateway-ihi.getenergy.io/api/token/users/associatekyc`,
  HeaderUrl: `https://gateway-ihi.getenergy.io/api/token/users/associatekyc`,
  methodType: "post",
  reqBody: [
    {
      adminOperatorId: "0.0.45961455",
      adminOperatorKey:
        "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
      clientId: "justpayme@gmail.com",
      operatorId: "0.0.46018633",
      operatorKey:
        "302e020100300506032b657004220420bebcf7647d8ae711fd2e009e2e3d18b32f333cb5de692b8cd560265c3bf00aba",
      tokenId: "0.0.46018711",
    },
  ],
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "Associated token and enable kyc successfully",
    response: null,
    httpStatus: "OK",
  },

  initialReq: [
    {
      adminOperatorId: "",
      adminOperatorKey: "",
      clientId: "",
      operatorId: "",
      operatorKey: "",
      tokenId: "",
    },
  ],
  screenName: "EnableKycUser",
};

export const transferTokenApiData = {
  url: `${REACT_APP_HTS_BASE_URL}token/transfer`,
  HeaderUrl: `${REACT_APP_HTS_BASE_URL}token/transfer`,
  methodType: "post",
  reqBody: {
    amount: 1000,
    clientId: "justpayme@gmail.com",
    fromSenderId: "0.0.45961455",
    fromSenderKey:
      "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
    toAccountId: "0.0.46018633",
    tokenId: "0.0.46018711",
  },
  headrerBody: [],
  responseBody: {
    status: 200,
    message: "Token transfered successfully",
    response: "9000",
    httpStatus: "OK",
  },

  initialReq: {
    amount: 0,
    clientId: "",
    fromSenderId: "",
    fromSenderKey: "",
    toAccountId: "",
    tokenId: "",
  },
  screenName: "AssociateKycUser",
};

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { errorToast, successToast } from "../../common/helper";
import { ADMIN_AUTH_BASE_URL } from "../../common/constants";
import { getAllClientsApi } from "../../redux/action/adminAction";
import { useDispatch } from "react-redux";

function AdminLogin() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const loginHandler = () => {
    // console.log(ADMIN_AUTH_BASE_URL);
    if (password === "" || userName === "") {
      toast.warning("Invalid fields");
    } else {
      setLoading(true);
      const req = {
        password: password,
        username: userName,
      };
      axios
        .post(ADMIN_AUTH_BASE_URL + `token/generate`, req)
        .then((user) => {
          const { message, status, response } = user.data;
          if (status === 200) {
            // console.log("redirected");
            navigate("/admin/dashboard");
            setLoading(false);
            successToast(message);
            sessionStorage.setItem(
              "accessToken",
              "Bearer " + user?.data?.accessToken
            );
            sessionStorage.setItem("refreshToken", user?.data?.refreshToken);
            sessionStorage.setItem("userId", user?.data?.id);
            sessionStorage.setItem("email", user?.data?.email);
            sessionStorage.setItem("roles", user?.data?.roles[0]);
            sessionStorage.setItem("username", user?.data?.username);
          } else {
            errorToast(message);
            setLoading(false);
          }
        })
        .catch((e) => {
          errorToast("Failed to login");
          setLoading(false);
        });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div>
        <div className="main">
          <div className="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <Grid
              container
              spacing={1}
              item={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid xs={8} md={8} lg={8} xl={8} sm={8}>
                <Card
                  className="logincard"
                  // style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <div class="sign-in-text">
                    <h2>Welcome Back Admin!</h2>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="logtxt">
                      <img
                        style={{ textAlign: "center" }}
                        className="back-img"
                        src="cc.png"
                      />
                    </div>

                    <div style={{ width: "48%" }}>
                      <h2 style={{ textAlign: "center" }}>Login</h2>
                      {/* <Typography sx={{ color: "rgb(60, 175, 106)" }}>
                        Email
                      </Typography> */}
                      <TextField
                        label="Email"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setUserName(e.target.value)}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "5px",
                        }}
                      />

                      <Grid sx={{ mt: 3 }}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          onChange={(e) => setPassword(e.target.value)}
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            style={{ backgroundColor: "white" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid>
                        <Button
                          fullWidth
                          className="logbutton"
                          onClick={loginHandler}
                          variant="contained"
                          size="large" 
                          sx={{
                            mt: 2.5,
                            mb: 1,
                            pt: 1.2,
                            pb: 1.2,
                            // background: "rgb(60, 175, 106)",
                          }}
                          disabled={loading}
                          endIcon={
                            loading ? (
                              <CircularProgress
                                size={20}
                                style={{
                                  color: "white",
                                }}
                              />
                            ) : (
                              <LockOpenIcon />
                            )
                          }
                        >
                          Submit
                        </Button>
                        <span></span>
                      </Grid>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;

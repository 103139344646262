import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "@mui/material/Modal";
import {
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
} from "@mui/material";
import { feesHandlerApi } from "../redux/action/adminAction";
import { useDispatch } from "react-redux";

function FeesModal({ open, setOpen, data, title, size, setFeesArr }) {
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id,
      service: data?.service,
      operations: data?.operations,
      priceInUsd: data?.priceInUsd,
      ihiFees: data?.ihiFeeInUsd,
    },

    validationSchema: Yup.object({
      service: Yup.string().required("Service is required"),
      operations: Yup.string().required("operation is required"),
      priceInUsd: Yup.string().required("Price is required"),
      ihiFees: Yup.string().required("IHI Fees is required"),
    }),

    onSubmit: (val) => {
      dispatch(feesHandlerApi(title, val, size, setFeesArr));
      formik.resetForm();
      setOpen(false);
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };
  return (
    <div>
      {" "}
      <Grid xs={12} md={6} lg={6} xl={3} sm={10}>
        <Modal
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              // handleClose();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Dialog
            className="editcharge"
            maxWidth={"xl"}
            fullWidth
            sx={{ margin: "auto", width: "40%", overflowX: "auto" }}
            open={open}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              className="flex justify-between items-center"
            >
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                  fontSize: "20px",
                }}
              >
                {title == "update" ? "Update Fees" : "Add Fees"}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <div>
                <b>Service:</b>
                <TextField
                  sx={{ mt: 1 }}
                  id="outlined-basic"
                  onChange={formik.handleChange}
                  name="service"
                  fullWidth
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  type="text"
                  value={formik.values.service}
                  error={Boolean(
                    formik.touched.service && formik.errors.service
                  )}
                  helperText={formik.touched.service && formik.errors.service}
                  placeholder="Service"
                />
              </div>
              <div style={{ marginTop: "18px" }}>
                <b>Operation:</b>
                <TextField
                  sx={{ mt: 1 }}
                  id="outlined-basic"
                  name="operations"
                  fullWidth
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  type="text"
                  value={formik.values.operations}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.operations && formik.errors.operations
                  )}
                  helperText={
                    formik.touched.operations && formik.errors.operations
                  }
                  inputProps={{
                    pattern: "[0-9]*",
                  }}
                  placeholder="Operation"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <b>Hedera Fee (USD):</b>
                <TextField
                  sx={{ mt: 1 }}
                  id="outlined-basic"
                  onChange={formik.handleChange}
                  name="priceInUsd"
                  fullWidth
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    } else if (event?.key === "-") {
                      event.preventDefault();
                    }
                  }}
                  value={formik.values.priceInUsd}
                  error={Boolean(
                    formik.touched.priceInUsd && formik.errors.priceInUsd
                  )}
                  helperText={
                    formik.touched.priceInUsd && formik.errors.priceInUsd
                  }
                  placeholder="Hedera Fee (USD)"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <b>IHI Fee (USD):</b>
                <TextField
                  id="outlined-basic"
                  onChange={formik.handleChange}
                  name="ihiFees"
                  fullWidth
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    } else if (event?.key === "-") {
                      event.preventDefault();
                    }
                  }}
                  value={formik.values.ihiFees}
                  error={Boolean(
                    formik.touched.ihiFees && formik.errors.ihiFees
                  )}
                  helperText={formik.touched.ihiFees && formik.errors.ihiFees}
                  placeholder="IHI Fee (USD)"
                />
              </div>
            </DialogContent>
            {/* <DialogActions sx={{ justifyContent: "center", padding: "20px" }}> */}
            <Grid sx={{ gap: "20px", textAlign: "center" }} mt={0}>
              <Button
                size="small"
                variant="contained"
                onClick={formik.handleSubmit}
                className="accept-btn"
                sx={{ margin: "5%", fontSize: "14px" }}
              >
                {title === "update" ? "Update" : "Save"}
              </Button>
              <Button
                sx={{ fontSize: "14px" }}
                size="small"
                variant="contained"
                color="error"
                onClick={handleClose}
                className="reject-btn"
              >
                Cancel
              </Button>
            </Grid>
            {/* </DialogActions> */}
          </Dialog>
        </Modal>
      </Grid>
    </div>
  );
}

export default FeesModal;

export const NftTokenDesc = [
  {
    header: "NFT Token creation",
    subHeader: "API reference for NFT Token Creation",
    description: "Create a new nft Token.",
    services: [
      {
        url: "api/hts/nft/create",
        type: "post",
        desc: "Create a new NFT Token.",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The field represents the project user name which is used while registering",
            },
            {
              fieldName: "fileId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The ID of the file",
            },
            {
              fieldName: "operatorId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The ID of the wallet",
            },
            {
              fieldName: "operatorKey",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The private key which is generated during wallet creation",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "response",
            value: "0.0.45909241",
            type: "String",
            description: "This is the NFT Token ID",
          },
        ],
      },
    ],
  },
];

export const TokenBalanceDesc = [
  {
    header: "NFT Token Balance",
    subHeader: "API reference for NFT Token Balance",
    description: "API to Check nft Token balance.",
    services: [
      {
        url: "api/hts/token/balance",
        type: "post",
        desc: "To check nft token balance.",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The field represents the project user name which is used while registering",
            },
            {
              fieldName: "operatorId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The ID of the wallet",
            },
            {
              fieldName: "tokenId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The ID of the token",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "response",
            value: "1",
            type: "String",
            description: "The Balance will display here",
          },
        ],
      },
    ],
  },
];

export const createWalletDesc = [
  {
    header: "Wallet creation",
    subHeader: "API reference for Wallet Creation",
    description: "Create a new wallet",
    services: [
      {
        url: "api/hedera/wallet/create",
        type: "post",
        desc: "Create a new wallet",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [],
        },
        responseFieldDetails: [
          {
            fieldName: "account",
            value: "0.0.45961455",
            dataType: "String",
            description:
              "The account ID is used to reference a specific account in transactions and queries. Hedera account IDs have the format x.y.z (eg 0.0.3)",
          },
          {
            fieldName: "privatekey",
            value:
              "302e020100300506032b657004220420baee0f194ca913b00e7d8709d4a7366dfb16c52ba9fc97a2b08d4920585c8e6b",
            dataType: "String",
            description:
              "tokenType is for user authentication need to send with accessToken.",
          },
          {
            fieldName: "shard",
            value: 0,
            dataType: "Int",
            description:
              "x represents the shard number (shardId). It will default to 0 today, as Hedera only performs in one shard.",
          },
          {
            fieldName: "real",
            value: 0,
            dataType: "Int",
            description:
              "y represents the realm number (realmId). It will default to 0 today, as realms are not yet supported.",
          },
          {
            fieldName: "accoundId",
            value: "45961455",
            dataType: "String",
            description:
              "z represents the account number, the equivalent of a human-friendly public key.",
          },
          {
            fieldName: "publickey",
            value:
              "302a300506032b65700321008750caae83c83b398196d7f35f343ea55d617f6bd60ab0d2b34359603b1626b4",
            dataType: "String",
            description:
              "The operator private key is used to sign all transactions executed by this client, private keys are generated by an algorithm and are unique to one another.",
          },
        ],
      },
    ],
  },
];

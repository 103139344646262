import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid, Button } from "@mui/material";
import { JsonEditor as Editor } from "jsoneditor-react";
import "../description.css";

function BodyComp({ handleClick, setValue, value, disableTry, screenName }) {
  return (
    <div>
      <Card className="parameterBackground">
        <CardContent sx={{ paddingTop: "0", paddingBottom: "0" }}>
          {Object.keys(value).length > 0 ? (
            <>
              <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                  <Typography style={{ textAlign: "left", padding: "10px" }}>
                    Parameter
                  </Typography>
                </Grid>
                <Grid item sx={6}></Grid>
              </Grid>
              <div className="editor">
                <Editor
                  mode="code"
                  enableTransform={false}
                  search={false}
                  className="jsoneditor-text"
                  onChange={(data) => {
                    setValue(data);
                  }}
                  value={value}
                />
              </div>
            </>
          ) : (
            ""
          )}
          {disableTry ? (
            ""
          ) : (
            <Button
              variant="outlined"
              style={{
                float: "right",
                color: "white",
                margin: "10px 0",
                backgroundColor: "hsl(224deg 49% 31%)",
              }}
              onClick={handleClick}
            >
              Try
            </Button>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default BodyComp;

import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { Button } from "@mui/material";
import GenerateToken from "../../pages/auth/GenerateToken";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useNavigate } from "react-router-dom";
import HcsMessage from "../../pages/hcs/PostHcsMessage";
import OpenDialog from "../common/showAuthorize";
import { makeStyles } from "@mui/styles";
import AcUnitIcon from "@mui/icons-material/AcUnit";

const useStyles = makeStyles({
  paper: {
    background: "#212f41 !important",
    color: "red",
    borderRight: "solid 1px #808080bd !important",
  },
});

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const classes = useStyles();
  let navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState({
    Auth: true,
    Hcs: true,
    Hedera: true,
    Hts: true,
    Admin: false,
  });
  const [inputValues, setInputValues] = useState([]);
  const [buttonList, setButtonList] = useState([]);
  let role = sessionStorage.getItem("roles");
  const updateAuth = (e) => {};
  const AuthInputValues = [
    {
      name: "Authorize",
      type: "text",
      value: "",
    },
  ];

  const apiInputValue = [
    { name: "Username", value: "ihi_sandbox", type: "text", onchangeInput: "" },
    {
      name: "Password",
      value: "Sandbox@test",
      type: "text",
      onchangeInput: "",
    },
  ];

  const pageNavigation = (path, comp) => {
    navigate(path);
  };

  const expandClick = ({ route, name }) => {
    setExpand((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };
  const userRoutepath = [
    {
      onclick: expandClick,
      headerName: "Auth",
      expand: expand.Auth,
      route: "",
      icon: <ArrowRightIcon />,
      subHeader: [
        {
          onclickNav: pageNavigation,
          headerName: "Token Generate",
          copm: <GenerateToken />,
          path: "/generate-token",
          icon: <ArrowRightIcon />,
          type: "post",
          exact: true,
        },
      ],
    },
    {
      onclick: expandClick,
      headerName: "Hcs",
      expand: expand.Hcs,
      route: "",
      icon: <ArrowRightIcon />,
      subHeader: [
        {
          onclickNav: pageNavigation,
          headerName: "Submit a message to a topic",
          path: "/submit-a-message-to-a-topic",
          icon: <ArrowRightIcon />,
          type: "post",
          copm: <HcsMessage />,
        },
        {
          onclickNav: pageNavigation,
          headerName: "Get hcs message",
          path: "/get-hcs-message",
          icon: <ArrowRightIcon />,
          type: "get",
          copm: "Under Construction",
        },
      ],
    },
    {
      onclick: expandClick,
      headerName: "Hedera",
      expand: expand.Hedera,
      route: "",
      icon: <ArrowRightIcon />,
      subHeader: [
        {
          onclickNav: pageNavigation,
          headerName: "Create Wallet",
          path: "/create-wallet",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "get",
        },
        {
          onclickNav: pageNavigation,
          headerName: "File Creation",
          path: "/create-file",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
      ],
    },
    {
      onclick: expandClick,
      headerName: "Hts",
      expand: expand.Hts,
      route: "",
      icon: <ArrowRightIcon />,
      subHeader: [
        // {
        //   onclickNav: pageNavigation,
        //   headerName: "Get fees list",
        //   path: "/get-fees-list",
        //   icon: <ArrowRightIcon />,
        //   copm: "Under Construction",
        //   type: "get",
        // },
        // {
        //   onclickNav: pageNavigation,
        //   headerName: "Save fees",
        //   path: "/save-fees",
        //   icon: <ArrowRightIcon />,
        //   copm: "Under Construction",
        //   type: "post",
        // },
        // {
        //   onclickNav: pageNavigation,
        //   headerName: "Hbar balance",
        //   path: "/hbar-balance",
        //   icon: <ArrowRightIcon />,
        //   copm: "Under Construction",
        //   type: "post",
        // },
        {
          onclickNav: pageNavigation,
          headerName: "Create nft token",
          path: "/create-nft-token",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        {
          onclickNav: pageNavigation,
          headerName: "Token balance",
          path: "/token-balance",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        {
          onclickNav: pageNavigation,
          headerName: "Burn Token",
          path: "/burn-token",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        {
          onclickNav: pageNavigation,
          headerName: "Create Token",
          path: "/create-token",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        {
          onclickNav: pageNavigation,
          headerName: "Current total supply",
          path: "/current-total-supply",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        {
          onclickNav: pageNavigation,
          headerName: "Mint token",
          path: "/mint-token",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        {
          onclickNav: pageNavigation,
          headerName: "Transfer token",
          path: "/transfer-token",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        // {
        //   onclickNav: pageNavigation,
        //   headerName: "Transfer token power transition mode",
        //   path: "/transfer-token-power-transition-mode",
        //   icon: <ArrowRightIcon />,
        //   copm: "Under Construction",
        //   type: "post",
        // },
        // {
        //   onclickNav: pageNavigation,
        //   headerName: "Token transfer simulator",
        //   path: "/token-transfer-simulator",
        //   icon: <ArrowRightIcon />,
        //   copm: "Under Construction",
        //   type: "post",
        // },
        {
          onclickNav: pageNavigation,
          headerName: "Associate kyc user",
          path: "/associate-kyc-user",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
        {
          onclickNav: pageNavigation,
          headerName: "Associate token and enable kyc",
          path: "/associate-token&enable-kyc",
          icon: <ArrowRightIcon />,
          copm: "Under Construction",
          type: "post",
        },
      ],
    },
    // {
    //   onclick: expandClick,
    //   headerName: "Admin",
    //   expand: expand.Hedera,
    //   route: "",
    //   icon: <ArrowRightIcon />,
    //   subHeader: [],
    // },
  ];
  const adminRoutePath = [
    {
      onclick: expandClick,
      headerName: "Admin",
      expand: expand.Auth,
      route: "",
      icon: <ArrowRightIcon />,
      subHeader: [
        {
          onclickNav: pageNavigation,
          headerName: "Users List",
          copm: <GenerateToken />,
          path: "/admin/dashboard",
          exact: true,
        },
        {
          onclickNav: pageNavigation,
          headerName: "User Transaction",
          copm: <GenerateToken />,
          path: "/admin/userTransaction",
          exact: true,
        },
        {
          onclickNav: pageNavigation,
          headerName: "Hedera Fees",
          copm: <GenerateToken />,
          path: "/admin/fees",
          exact: true,
        },
        {
          onclickNav: pageNavigation,
          headerName: "Hedera Fees Report",
          copm: <GenerateToken />,
          path: "/admin/feesReport",
          exact: true,
        },
      ],
    },
  ];

  const routeArr = role === "ROLE_SUPER_ADMIN" ? adminRoutePath : userRoutepath;

  const getApiKey = () => {
    setInputValues(apiInputValue);
    setButtonList(["Close"]);
    setOpen(true);
  };

  const authorizeUser = () => {
    setInputValues(AuthInputValues);
    setButtonList(["Authorize", "Close"]);
    setOpen(true);
  };

  const logout = () => {
    navigate("/login");
  };
  const authAction = (val) => {
    sessionStorage.setItem("authorized", val);
    sessionStorage.setItem("auth", true);
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{ backgroundColor: "white" }}
          enableColorOnDark
        >
          <Toolbar>
            {role === "ROLE_SUPER_ADMIN" ? (
              <Typography
                sx={{
                  flexGrow: 1,
                  color: "#f69518",
                }}
              >
                Admin
              </Typography>
            ) : (
              <Typography
                sx={{
                  flexGrow: 1,
                  color: "#f69518",
                }}
              >
                {"API Reference"}
              </Typography>
            )}
            {role === "ROLE_SUPER_ADMIN" ? (
              <Button
                onClick={logout}
                className="btn btn1"
                variant="outlined"
                sx={{
                  mr: 1,
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "700",
                  background: "#f3712c",
                }}
              >
                Logout
              </Button>
            ) : (
              <div>
                <Button
                  onClick={authorizeUser}
                  className="btn btn1"
                  variant="outlined"
                  sx={{
                    mr: 5,
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "700",
                    background: "#f3712c",
                  }}
                >
                  Authorize User
                </Button>
                <Button
                  onClick={getApiKey}
                  className="btn btn1"
                  variant="outlined"
                  sx={{
                    mr: 5,
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "700",
                    background: "#f3712c",
                  }}
                >
                  Get Api
                </Button>
                <Button
                  onClick={logout}
                  className="btn btn1"
                  variant="outlined"
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "700",
                    background: "#f3712c",
                  }}
                >
                  Admin Login
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          classes={{ paper: classes.paper, drawer: classes.list }}
          className="sidebar-drawer"
          variant="permanent"
          open={true}
        >
          <Divider />
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "hsl(224deg 49% 31%) !importan",
              color: "white !important",
              marginTop: 10,
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {routeArr.map((ele, i) => {
              return (
                <React.Fragment key={i}>
                  <ListItemButton
                    sx={{
                      borderBottom: "1px solid #808080bd !important",
                    }}
                    onClick={(e) =>
                      ele.onclick({
                        rout: ele.route,
                        name: ele.headerName,
                      })
                    }
                  >
                    <ListItemText primary={ele.headerName} />
                    {ele.expand === true ? (
                      <ExpandLess />
                    ) : ele.expand === false ? (
                      <ExpandMore />
                    ) : null}
                  </ListItemButton>
                  <Collapse
                    in={ele.expand}
                    timeout="auto"
                    unmountOnExit
                    className="collapse-list"
                  >
                    {ele.subHeader.map((e, index) => {
                      return (
                        <React.Fragment key={index}>
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 2.5 }}
                              onClick={(event) => e.onclickNav(e.path, e.copm)}
                            >
                              <ListItemText
                                className="API-list-item"
                                primary={e.headerName}
                              />
                            </ListItemButton>
                          </List>
                        </React.Fragment>
                      );
                    })}
                  </Collapse>
                </React.Fragment>
              );
            })}
          </List>
        </Drawer>
      </Box>
      <OpenDialog
        open={open}
        inputValues={inputValues}
        setOpen={setOpen}
        title={"Authorization"}
        buttons={buttonList}
        authorizeuser={authAction}
      />
    </>
  );
}

import { Box, Button, Card, Grid, TablePagination } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MiniDrawer from "../../components/navBar/Sidenav";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import {
  fetchAllClients,
  getTransactionsApi,
} from "../../redux/action/adminAction";
import { adminSelector } from "../../redux/slice/adminSlice";
import DashDateRangePicker from "../../common/DashDateRangePicker";
import Loading from "../../components/common/loading/Loading";
// import moment from "moment";

function UserTranscation() {
  const dispatch = useDispatch();
  const {
    fetchClients,
    getTransactionsAct,
    transactionElements,
    TransactionsLoading,
  } = useSelector(adminSelector);
  const [clientId, setClientId] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date().setDate(new Date().getDate() - 6),
    endDate: new Date(),
  });
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const paginationRowsOptions = [5, 10, 20, 50, 100];
  useEffect(() => {
    dispatch(fetchAllClients(setClientId));
  }, []);

  useEffect(() => {
    const req = {
      clientId: clientId,
      fromDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
      pageNo: page,
      pageSize: size,
      toDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
    };
    if (clientId) {
      dispatch(getTransactionsApi(req));
    }
  }, [page, size, clientId, dateRange.endDate]);

  const handlePerRowsChange = async (event) => {
    setPage(0);
    setSize(+event.target.value);
  };
  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
  };
  const dateRangeHandler = (e) => {
    setDateRange(e);
  };

  const clientHandler = (e) => {
    setClientId(e);
  };

  return (
    <main className="respon">
      <div>
        <MiniDrawer />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
        </Box>
        <Grid xs={12} md={2} lg={3} xl={3} p={2}>
          <div className="wallettkn">
            <select
              onChange={(e) => clientHandler(e.target.value)}
              style={{
                padding: "15.5px",
                borderRadius: "5px",
                border: "1px solid lightgrey",
                outline: "none",
                fontWeight: "bold",
                fontSize: "17px",
                cursor: "pointer",
              }}
            >
              {fetchClients?.map((e, i) => {
                return <option value={e.userName}>{e.userName}</option>;
              })}
            </select>

            <DashDateRangePicker
              handler={dateRangeHandler}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </div>
          <h5
            style={{
              fontSize: "18px",
              marginBottom: "10px",
            }}
          >
            Client Transactions
          </h5>
          <Card style={{}} className="cardtable" sx={{ overflowX: "auto" }}>
            <table
              style={{ textAlign: "center" }}
              className
              table
              table-border
              datatable
              dataTable-table
            >
              <thead>
                <tr class="thead">
                  <th scope="col">Date</th>
                  <th scope="col">Client Id</th>
                  <th scope="col">operation</th>
                  <th scope="col">Hedera Fee (USD)</th>
                  <th scope="col">IHI Fee (USD)</th>
                  <th scope="col">Profit (USD)</th>
                </tr>
              </thead>
              <tbody>
                {getTransactionsAct?.htsInfos?.map((e, i) => {
                  return (
                    <tr>
                      <td>{e.createdTime}</td>
                      <td>{e.clientId}</td>
                      <td>{e.operation}</td>
                      <td>{e.fees}</td>
                      <td>{e.ihiFees}</td>
                      <td>{e.profit}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Total Hedera Fee (USD)</td>
                <td colSpan={4}></td>
                <td style={{ fontWeight: "bold" }}>
                  {getTransactionsAct?.totalHederaFee}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Total IHI Fee (USD)</td>
                <td colSpan={4}></td>
                <td style={{ fontWeight: "bold" }}>
                  {getTransactionsAct?.totalIhiFee}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Total Profit Fee (USD)</td>
                <td colSpan={4}></td>
                <td style={{ fontWeight: "bold" }}>
                  {getTransactionsAct?.totalFeeProfit}
                </td>
              </tr>
            </table>
            <Card>
              {/* <thead>
                <tr className="">
                  <th scope="col">Fees</th>
                </tr>
              </thead>
              {getTransactionsAct?.map((e, i) => {
                return (
                  <>
                    <tr>
                      <td>{e.totalFee}</td>
                    </tr>
                  </>
                );
              })} */}
            </Card>
            <TablePagination
              rowsPerPageOptions={paginationRowsOptions}
              component="div"
              count={transactionElements || 0}
              rowsPerPage={size}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePerRowsChange}
              className="pagination-text"
            />
          </Card>
        </Grid>
      </div>
      {TransactionsLoading && <Loading />}
    </main>
  );
}

export default UserTranscation;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Backdrop } from "@mui/material";

export default function LoadingPage() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    handleToggle();
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    // <Dialog
    //   open={open}
    //   keepMounted
    //   sx={
    //     {
    //       // backgroundColor: "transparent",
    //     }
    //   }
    // >
    //   <div className="lds-hourglass"></div>
    // </Dialog>
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <div class="center">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
    </Backdrop>
  );
}
